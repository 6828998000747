@tailwind base;
@tailwind components;
@tailwind utilities;


.linear {
    background: linear-gradient(to right, #069125, #022B0B);
  /* Adjust the colors as per your preference */
  -webkit-background-clip: text;
  /* This property ensures that the gradient is clipped to the text */
  -webkit-text-fill-color: transparent;
}

.linear-bg {
  background: linear-gradient(to right, #069125, #022B0B);
/* Adjust the colors as per your preference */
}

.linear-transit {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.2));
  /* Adjust the opacity values (0.5 and 0.2) as per your preference */
}